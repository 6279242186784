import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import './App.css';

function App() {
  const [countdown, setCountdown] = useState(3);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 1) {
          clearInterval(interval);
          setRedirecting(true);
          setTimeout(() => {
            window.location.href = 'https://mertnagas.com';
          }, 1000);
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <div className="centered-text">
        <p className="redirecting-text">
          {redirecting ? 'Redirecting...' : `Redirecting in ${countdown}...`}
        </p>
        <LinearProgress
          variant="determinate"
          value={redirecting ? 100 : 100 - (countdown / 3) * 100}
          sx={{
            width: '100%',
            backgroundColor: '#ccc',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#090979',
            },
          }}
        />
      </div>
    </div>
  );
}

export default App;
